<template>
  <el-dialog
    title="我要提問"
    :visible.sync="isOpen"
    class="feedback-dialog"
    @closed="closedEvent"
  >
    <el-form
      v-if="!isLoading"
      style="width: 100%;"
      :model="FeedBackForm"
      label-position="top"
      ref="FeedBackForm"
      :rules="FeedBackRules"
      class="cs-form "
      data-style="4"
    >
      <div class="row pl-2 pr-2 mb-2">
        <div class="col-12 col-md-2 col-lg-2 ">
          <label class="title necessary">標題</label>
        </div>
        <div class="col-12 col-md-10 col-lg-10 " style="padding-left: 0px;">
          <el-form-item prop="feedBackTitle" class="col-12 col-md-9 mb-2">
            <el-select
              :style="`display: block;`"
              v-model="FeedBackForm.feedBackTitle"
              placeholder="請選擇"
            >
              <el-option
                v-for="item in feedBackTitleList"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="row pl-2 pr-2 mb-2">
        <div class="col-12 col-md-2 col-lg-2 ">
          <label class="title necessary">內容</label>
        </div>
        <div class="col-12 col-md-10 col-lg-10 ">
          <el-form-item prop="feedBackContent">
            <textarea
              v-model="FeedBackForm.feedBackContent"
              class="form-control"
              style="height:250px;"
            ></textarea>
            <div class="text--wordLimt">{{ FeedBackForm.feedBackContent.length }}/150</div>
          </el-form-item>
        </div>
      </div>
      <div class="row pl-2 pr-2 mb-2">
        <div class="col-12 col-md-2 col-lg-2 ">
          <label class="title ">檔案上傳</label>
        </div>
        <div class="col-12 col-md-10 col-lg-10 ">
          <input type="file" ref="file" id="file-uploader" @change="CheckFile" />
          <div slot="tip" class="el-upload__tip">
            只能上傳jpg/png文件，且不超過2MB
          </div>
        </div>
      </div>
      <div class="row pl-2 pr-2 mb-2">
        <div class="col-12 col-md-2 col-lg-2 ">
          <label class="title necessary">驗證碼</label>
        </div>
        <el-form-item prop="captchaCode" class="col-12 col-md-5">
          <el-input
            :class="`el-input__input`"
            placeholder="驗證碼"
            type="text"
            id="captchaCode"
            v-model="FeedBackForm.captchaCode"
          />
        </el-form-item>
        <div class="col-12 col-md-4">
          <img
            class="img-verify"
            :src="`data:image/png;base64,${CodeImg}`"
            @click="GetCaptchaCode"
            alt="驗證碼"
          />
        </div>
      </div>
      <div class="footer">
        <div></div>
        <div class="text-right">
          <button
            type="button"
            class="btn--gray px-3 py-1 py-md-1"
            @click="AddMemberFeedBack"
          >
            送出
          </button>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { AddMemberFeedBack } from '@/api/FeedBackApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';
import { GetFeedbackTitle } from '@/api/Base/BaseApi';

export default {
  props: ['show', 'subjectName', 'questionCode'],
  data() {
    return {
      isOpen: false,
      isLoading: true,
      feedBackTitleList: [],
      FeedBackForm: {
        feedBackKind: 2,
        feedBackTitle: '',
        feedBackContent: '',
        iploadType: 11,
        code: '',
        captchaCode: '',
      },
      FeedBackRules: {
        feedBackTitle: [
          { required: true, message: '請選擇標題', trigger: 'change' },
        ],
        feedBackContent: [
          { required: true, message: '請輸入內容', trigger: 'blur' },
          { max: 150, message: '內容不能超過150個字' },
        ],
        captchaCode: [{ required: true, message: '請輸入驗證碼', trigger: 'blur' }],
      },
      CodeImg: '',
    };
  },
  mounted() {},
  methods: {
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.FeedBackForm.code = response.data.data.code;
          GetCaptchaBase64(this.FeedBackForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    GetFeedbackTitle() {
      GetFeedbackTitle().then((response) => {
        if (response.data.statusCode === 0) {
          this.feedBackTitleList = this._.map(response.data.data, (item) => ({
            label: item.name,
            value: item.id,
          }));
        }
      });
    },
    CheckFile() {
      const file = this.$refs.file.files[0];
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.$message.error('上傳圖片只能是 JPG/PNG 格式!');
        this.$refs.file.value = '';
      }
      if (!isLt2M) {
        this.$message.error('上傳圖片大小不能超過 2MB!');
        this.$refs.file.value = '';
      }
    },
    AddMemberFeedBack() {
      this.$refs.FeedBackForm.validate((valid) => {
        if (valid) {
          this.FeedBackForm.feedBackTitle += ` 〉${this.subjectName}#${this.questionCode}`;

          const uploadedFile = this.$refs.file.files[0];
          const formData = new FormData();
          const objKey = Object.keys(this.FeedBackForm);
          objKey.forEach((item) => {
            formData.append(item, this.FeedBackForm[item]);
          });
          if (uploadedFile) formData.append('files', uploadedFile);
          AddMemberFeedBack(formData).then((response) => {
            if (response.data.statusCode === 0) {
              this.$message({
                showClose: true,
                type: 'success',
                message: '送出成功',
              });

              this.closedEvent();
            } else {
              this.GetCaptchaCode();
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '請將資料填寫完整',
          });
        }
      });
    },
    closedEvent() {
      this.FeedBackForm = {
        feedBackKind: 2,
        feedBackTitle: '',
        feedBackContent: '',
        iploadType: 11,
        code: '',
        captchaCode: '',
      };
      this.$refs.file.value = '';
      this.isOpen = false;

      this.$emit('update:show', false);
    },
  },
  watch: {
    async show(val) {
      if (val) {
        await Promise.all([
          this.GetCaptchaCode(),
          this.GetFeedbackTitle(),
        ]);

        this.isLoading = false;
        this.isOpen = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text--wordLimt {
  font-size: 12px;
  line-height: 0;
  position: absolute;
  bottom: 11px;
  right: 3px;
}

.feedback-dialog {
  /deep/ .el-dialog {
    width: calc(100% - 30px);
    max-width: 800px;

    border-radius: calc(0.3rem - 1px);
    overflow: hidden;

    @media (max-width: 767px) {
      max-width: 500px;
      margin-top: 2rem !important;
    }

    &__header {
      padding: 1.5rem 1rem;
      background-color: #efefef;
      border-bottom: 1px solid #dee2e6;
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
      color: #212529;
    }

    &__close {
      font-size: 22px;
      color: #777;
      font-weight: 700;
    }

    &__body {
      background-color: #f6f6f6;
    }
  }
}
</style>
